<template>
  <div class="container pt-3">
    <div v-if="haveCashback" class="claim">
      <div class="float-left">
        <span class="responsiveElipsis">{{ cashbackAmount }} {{ currency }}</span>
      </div>
      <div class="float-right">
        <button @click="this.claimCashback()" v-bind:class="cashbackClaiming == true ? 'txtdisabled' : ''"
          title="Claim Cashback">
          CLAIM
        </button>
        <button title="About Cashback" data-toggle="modal" data-target="#exampleModalLive"
          style="margin-left: 5px; padding: 5px 15px" @click="showPopup">
          <img src="/images/info.svg" style="width: 20px; height: 20px" />
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
    <div v-if="haveCashback" class="text-start text-hint ps-1 pt-1 mb-3">
      Instant Cashback
    </div>
    <div class="row">
      <div class="col col-10 ballsHolder m-0 p-0" ref="ballsHolder">
        <div v-for="item in kenoGame.board" :key="item.index" ref="itemColumn" class="itemColumn">
          <div class="kenoitem" :class="item.cssclass" @click="kenoGame.clickHandler(item.index)">
            {{ item.index }}
          </div>
        </div>
        <div class="clearfix"></div>

        <div class="buttons mb-3">
          <div class="kenoconnect" v-if="state.buttonstate == 'unlock'">
            <button class="betbutton" @click="kenoGame.unlock()">UNLOCK</button>
          </div>
          <div class="clearfix"></div>
          <div class="kenoconnect" v-if="state.buttonstate == 'connect'">
            <button class="betbutton" @click="kenoGame.connect()">
              CONNECT
            </button>
          </div>
          <div class="clearfix"></div>

          <div class="gameBet" v-if="state.buttonstate == 'play'">
            <div class="text-start text-hint ps-1">Min Bet {{ minBet }} / Max Bet {{ maxBet }} {{ currency }}</div>
            <input class="betinput" type="number" v-model="betAmount" />
            <button @click="betAmount = gameHelper.modifyBet(betAmount, 1)" class="betModifiers">
              x2
            </button>
            <button @click="betAmount = gameHelper.modifyBet(betAmount, 2)" class="betModifiers">
              x/2
            </button>
            <div></div>
            <div class="text-start text-hint ps-1 pt-1" v-if="betAmount > 0 && haveCashback">
              Cashback amount {{ calculateCashback(betAmount) }}
              {{ currency }}
            </div>
            <div class="gameButtons">
              <button class="randombutton" @click="kenoGame.random10()" :disabled="!state.gameBet" ref="randomBtn">
                <i class="tio-shuffle"></i>
              </button>
              <button v-if="!autobetSettings.state" class="betbutton bet" @click="makeBet()"
                :disabled="!state.gameBet || autobetSettings.state" ref="makeBet">
                <span v-if="state.gameBetloader && !state.gameBet" class="spinner-border text-warning me-2"
                  role="status" aria-hidden="true"></span>
                <span>BET</span>
              </button>

              <button v-if="autobetSettings.state" class="betbutton" @click="
                autobetSettings = gameHelper.autobetStop(autobetSettings)
              ">
                <span v-if="state.gameBetloader && !state.gameBet" class="spinner-border text-warning me-1"
                  role="status" aria-hidden="true"></span>
                <span>STOP</span>
              </button>

              <Autobet @autobet="autobetStart" :game="state" />
            </div>
            <div class="clearleft"></div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="betstabHolder pt-1">
          <div class="betstab" v-bind:class="
            historyManager.currentBetHistoryList == 'MY' ? 'betstabactive' : ''
          " @click="historyManager.setBetHistoryList('MY')">
            MY
          </div>
          <div class="betstab" v-bind:class="
            historyManager.currentBetHistoryList == 'ALL' ? 'betstabactive' : ''
          " @click="historyManager.setBetHistoryList('ALL')">
            ALL
          </div>
          <div class="clearfix"></div>
          <TokenHistoryTable :data="historyManager.betHistory" />
        </div>
      </div>
      <div class="col col-2 p-0">
        <div style="line-height: 1rem" v-for="(item, index) in kenoGame.coefs" :key="index">
          <div class="kenocoeffitem">
            <div class="hittxt" style="font-size: 0.7rem" v-bind:class="item.active ? 'visible' : 'hiddenfast'">
              Hit {{ kenoGame.coefs.length - index - 1 }}
            </div>
            <div style="margin-bottom: 4px" v-bind:class="item.active ? 'visible' : 'hiddenfast'">
              <div style="font-size: 0.9rem" :class="
                kenoGame.intersected >= kenoGame.coefs.length - index - 1
                  ? 'kenocoeffwin'
                  : 'kenocoeffnormal'
              ">
                {{ item.coeff }}x
              </div>
            </div>
            <div class="kenocoeffline"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>
    <div class="betstabbottomline"></div>
    <Popup v-show="wrongNetwork.state" @close="closePopup" :title="wrongNetwork.title" :body="wrongNetwork.body"
      fontSize="normal" />
    <Popup v-show="isPopupVisible" @close="closePopup" title="INSTANT CASHBACK RULES" :body="getCashbackText()" />
    <PromoPopup
      :state="dailyCashbackPopup.state"
      :title="dailyCashbackPopup.title"
      :body="dailyCashbackPopup.body"
      :closeButton="true"
      fontSize="normal"
    />

  </div>
</template>

<script>
//import { ref, onMounted } from 'vue' kenoitemselected
import KenoGame from "../index.js";

import Popup from "@/components/Popup.vue";
import CashbackHelper from "../../../services/cashbackhelper.js";
import Cashback from "../../../services/Cashback.min.json";

import Promo2 from "../../../services/Promo2.min.json";
import Promo2Helper from "../../../services/promo2helper";

import gameHelper from "../../helpers/gameHelper.js";
import Autobet from "../../components/Autobet";
import TokenHistoryTable from "../../components/TokenHistoryTable";

import HistoryManager from "../../../services/historymanager";
import Keno from "../contracts/Keno2.min.json";
import PromoPopup from "@/components/PromoPopup.vue";

import Token from "../../../services/Token.min.json";
import TokenHelper from "../../../services/tokenhelper.js";


export default {
  name: "GamePlay",
  components: {
    TokenHistoryTable,
    Autobet,
    Popup,
    PromoPopup
  },
  data() {
    const state = {
      kenomessage: true,
      kenowin: false,
      kenoWinAmount: "0.0",
      gameBet: false,
      gameBetloader: false,
      buttonstate: "connect",
      kenoMessageStr: "Connecting...",
      game: "keno",
      seletectedItems: 0,
    };

    var kenoGame = null;
    var historyManager = null;
    var cashbackHelper = null;
    var promo2Helper = null
    var tokenHelper = null
    return {
      kenoGame,
      historyManager,
      cashbackHelper,
      promo2Helper,
      tokenHelper,
      state,
      autobetSettings: false,
      haveCashback: true,
      cashbackAmount: 0,
      cashbackClaiming: true,
      isPopupVisible: false,
      gameHelper: new gameHelper(state.game),
      betAmount: this.$store.getters.getGameLimits[state.game].min,
      minBet: this.$store.getters.getGameLimits[state.game].min,
      maxBet: this.$store.getters.getGameLimits[state.game].max,
      currency: this.$store.getters.getCurrency,
      wrongNetwork: {
        state: false,
        title: 'Wrong Network',
        body: `You are trying to play with {{url network}} but you are connected to {{user network}}. 
                Please connect to {{url networ}} and click reload button below
                <div class="text-center"><a class="btn btn-secondary btn-sm mt-3">Reload</a></div>`,
      },
      dailyCashbackPopup: {
        state: false,
        title: "We've got you covered!",
        body: `<center><div style="text-align: left;font-size:19px;font-weight:600;">As it was your first bet today in this game, we have REFUNDED 1 TT back to your Cashback balance, to retrieve it just press the cashback "Claim" button!</div></center>`,
      }
    };
  },
  unmounted() {
    this.kenoGame.destroy()
    this.cashbackHelper.destroy();
    this.historyManager.destroy()
    this.promo2Helper.destroy();
    this.tokenHelper.destroy()
  },
  beforeMount() {
    this.historyManager = new HistoryManager(this.historyHandler, this.state.game, Keno);
    this.kenoGame = new KenoGame(this.messageHandler, Keno, this.state.game);
    this.kenoGame.historyManager = this.historyManager;
    this.kenoGame.init();
    this.$store.getters.getConfig.games[this.state.game].contract = this.kenoGame.address
    this.cashbackHelper = new CashbackHelper(this.messageHandler, Cashback);
    this.haveCashback = this.$store.state.user.currency.cashback;
    this.promo2Helper = new Promo2Helper(this.messageHandler, Promo2);
    this.tokenHelper = new TokenHelper(this.messageHandler, Token);

  },

  mounted() {
    this.$store.commit("setEnableFast", true);

    this.boardItems;
    this.matchSizes();

    this.$nextTick(function () {
      window.addEventListener("resize", this.matchSizes);
    });
  },
  watch: {
    windowSize: function () {
      this.matchSizes();
    },
    "state.kenoMessageStr": function (gameMessage) {
      if (gameMessage != "") {
        this.$toast.show(gameMessage);
      }
      this.state.kenoMessageStr = "";
    },
    "$store.state.user.currency": function () {
      this.setCurrency(this.$store.state.user.currency);
    },
  },
  methods: {
    historyHandler(obj, list) {
      if (this.kenoGame.playeraddress != obj.address) {
        this.historyManager.addBetHistory(obj, list)
      }

    },
    setCurrency(_currency) {
      console.log(_currency)
      this.currency = _currency.name.toUpperCase();
      this.haveCashback = _currency.cashback;

      this.kenoGame.setCurrency(_currency);

      this.state.buttonstate = "play";
      this.minBet = this.$store.getters.getGameLimits[this.state.game].min;
      this.maxBet = this.$store.getters.getGameLimits[this.state.game].max;
    },
    makeBet() {

      if (!this.gameHelper.verify("betAmount", this.betAmount)) {
        return this.$toast.error(
          this.gameHelper.getMessage("invalid_bet_amount")
        );
      }
      if (
        this.kenoGame.getSelectedItems().length < 1 ||
        this.kenoGame.getSelectedItems().length > 10
      ) {
        if (
          typeof this.autobetSettings != "undefined" &&
          this.autobetSettings
        ) {
          this.autobetSettings.state = false;
        }
        return this.$toast.error(
          this.gameHelper.getMessage("select_keno_items")
        );
      }

      this.kenoGame.betHandler(this.betAmount);
    },
    autobetStart(settings) {
      this.state.seletectedItems = this.kenoGame.getSelectedItems().length
      settings.seletectedItems = this.state.seletectedItems
      this.autobetSettings = this.gameHelper.autobetStart(settings);
      return this.makeBet();
    },
    autobetProcess(result) {
      this.gameHelper.sleep(this.$store.getters.getConfig.games[this.state.game].delay)
      this.autobetSettings = this.gameHelper.autobet(
        this.autobetSettings,
        result
      );
      if (this.autobetSettings.state) {
        //start new raund
        this.betAmount = this.autobetSettings.bet ? this.autobetSettings.bet.current : 0;
        if (this.autobetSettings.randomNumbers.make) {
          this.kenoGame.random10(this.autobetSettings.seletectedItems);
        }
        return this.makeBet();
      }
      //finish autobet
      this.autobetSettings = this.gameHelper.autobetStop(this.autobetSettings);
      return (this.betAmount = this.autobetSettings.bet.initial);
    },
    matchSizes() {
      var viewportHeight = window.innerHeight - window.innerHeight * 0.8;
      var boardWidth = this.$refs.ballsHolder.clientWidth;
      var boardHeight = this.$refs.ballsHolder.clientHeight;
      var margin = 5;
      var hBall = boardHeight / 5.5;
      var ballSize = hBall;

      if (boardHeight > viewportHeight) {
        ballSize = viewportHeight / 5.5;
      }

      ballSize = boardWidth / 8.8;

      ballSize = ballSize - margin - margin;

      document.querySelectorAll(".itemColumn").forEach(function (element) {
        element.style.width = ballSize + "px";
        element.style.height = ballSize + "px";
        element.style.setProperty("margin", margin + "px", "important");
      });

      var lineHeight = ballSize - ballSize * 0.1;

      document.querySelectorAll(".kenoitem").forEach(function (element) {
        element.style.width = ballSize + "px";
        element.style.height = ballSize + "px";
        element.style.lineHeight = lineHeight + "px";
        element.style.setProperty("margin", margin + "px", "important");
      });
    },
    messageHandler(obj) {
      // if (obj.type !== "balance") {

      // }
      //type=balance modis roca balasi ganaxlda

      if (obj.type == "limits") {
        window.Logger.log(obj)
        this.betAmount = obj.min;
        this.minBet = obj.min;
        this.maxBet = obj.max;
        this.$store.getters.getGameLimits[this.state.game].min = this.minBet;
        this.$store.getters.getGameLimits[this.state.game].max = this.maxBet;
        this.state.gameBet = true;
        return;
      }
      //
      if (obj.type == "tokennotalowed") {
        this.state.buttonstate = "unlock";
        return;
      }
      if (obj.type == "tokenalowed") {
        this.state.buttonstate = "play";
        return;
      }


      if (obj.type == "balance") {
        if (obj.currency != this.currency) {
          return
        }
        let balance = parseFloat(obj.balance);
        if(obj.currency === 'TT'){
          this.$store.commit("setBalance", balance.toFixed(2));
        }else{
          this.$store.commit("setBalance", balance.toFixed(5));
        }
        this.$store.commit("setCurrency", obj.currency);

        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }

      if (obj.type == "cashbackadd") {
        this.cashbackAmount = obj.balance;
        if (parseFloat(this.cashbackAmount) > 0) {
          this.cashbackClaiming = false;
        }

        return;
      }
      if (obj.type == "tokenbalance") {
        console.log(obj)
        this.$store.commit("setTokenBalance", parseFloat(obj.balance).toFixed(3));
        return;
      }
      if (obj.type == "cashbackclaim") {
        this.$toast.success("Claimed " + obj.amount + " " + this.currency, {
          duration: 3000,
        });
        this.diceGame.fetchAccount();
        this.cashbackHelper.balanceOf();
        return;
      }

      //type=bethistory modis roca beti dafiksirda obj.address obj.bet obj.win
      if (obj.type == "accountset") {
        if (this.$store.getters.getUserWallet != obj.msg) {
          this.$store.commit("setUserWallet", obj.msg);
        }
        this.kenoGame.playeraddress = obj.msg;
        if (this.cashbackHelper.account == null) {
          this.cashbackHelper.onConnet(obj.msg);
          this.cashbackHelper.balanceOf();
        }
        if (this.tokenHelper.account == null) {
          this.tokenHelper.onConnet(obj.msg);
          this.tokenHelper.balanceOf();
        }
        if (this.promo2Helper.account == null) {
          console.log(obj.msg)
          this.promo2Helper.onConnet(obj.msg);
        }
        if (
          this.$store.state.user.currency.address !=
          this.kenoGame.tokenObj.address
        ) {
          this.setCurrency(this.$store.state.user.currency);
        }
        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }
      if (obj.type == "dailycashback") {
        console.log(obj)
        this.dailyCashbackPopup.state = true;
      }
      if (obj.type == "networkchange") {
        console.log(obj);
        var url = this.gameHelper.getRedirectUrl(this.state.game, obj.networkVersion)
        console.log(url)
        if (url != null) {
          window.location.href = url
        } else {
          var chains = this.gameHelper.getChainsList(this.state.game)
          window.Logger.log(chains)
          this.wrongNetwork.state = true
          this.wrongNetwork.body = "This game is not available on this Chain/Token<br/><br/>"
          this.wrongNetwork.body += "You can play this game on the next Cnain(Tokens)<br/><br/>"
          for (let index = 0; index < chains.length; index++) {
            const element = chains[index];
            this.wrongNetwork.body += `${element.blockchain_name}(${element.currency_codes.join(', ')})<br/><br/>`
          }
          this.wrongNetwork.body += "Please change to correct Chain/Token<br/>"
          this.wrongNetwork.body += `and click reload button below
                <div class="text-center"><a href="${window.location.href}" class="btn btn-secondary btn-sm mt-3">Reload</a></div>`


        }
        // 
        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }


      if (obj.type == "bethistory") {
        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }

      //type=error modis roca shecdoma dafiksirda
      if (obj.type == "error") {
        this.state.kenoMessageStr = obj.msg;
        this.state.kenomessage = true;
        setTimeout(
          function () {
            this.state.kenomessage = false;
          }.bind(this),
          3000
        );
        if (this.autobetSettings) {
          this.autobetSettings = this.gameHelper.autobetStop(
            this.autobetSettings
          );
          this.betAmount = this.autobetSettings.bet ? this.autobetSettings.bet.current : 0;
        }
      } else {
        this.state.kenowin = false;
        this.state.gameBet = false;
        this.state.gameBetloader = false;
        this.state.buttonstate = "play";

        //type=connected modis roca metamaskze dakonektda
        if (obj.type == "connected") {
          this.$store.commit("setCurrencyEnabled", this.$store.getters.getConfig.games[this.state.game].tokens);
          this.state.kenomessage = false;
          this.state.gameBet = true;
        }

        //type=rejected modis roca userma metamaskze rejecti misca konektze da vachvenebt konektis gilaks
        //type=notconnected  modis roca useri araa dakonektebuli da vachvenebt konektis gilaks
        //da vmalavt mesijis divs
        if (obj.type == "rejected" || obj.type == "notconnected") {
          this.state.kenomessage = false;
          this.state.buttonstate = "connect";
          this.autobetSettings = this.gameHelper.autobetStop();
        }

        //type=betloader modis roca beti gavida blockchainze da velodebit dadasturebas, vachvenenbt preloaders
        if (obj.type == "betloader") {
          this.state.gameBetloader = true;
        }

        //type=betfinished modis roca userma an moigo an waago obj.win ari tanxa
        // tu tanxa=0 chveulebrivad betis knopkas vachvenebt
        // tu tanxa>0 chveulebrivad betis knopkas vachvenebt
        if (obj.type == "betfinished") {
          this.tokenHelper.balanceOf();
          if (obj.win > 0) {
            this.state.kenowin = true;
            this.state.kenoWinAmount = obj.win;

            this.$toast.success(
              this.gameHelper.getMessage("you_won_amount", obj.win),
              { duration: 3000 }
            );
            this.$soundManager.play({ name: 'win' })
            this.state.kenowin = false;
            this.state.gameBet = true;
          } else {
            this.state.gameBet = true;
          }
          this.historyManager.addBetHistory(
            {
              address: "ME",
              bet: obj.bet,
              win: obj.win,
              tx_id: obj.tx_id,
              currency: obj.currency
            }, 'MY')

          this.historyManager.addBetHistory(
            {
              address: obj.address,
              bet: obj.bet,
              win: obj.win,
              tx_id: obj.tx_id,
              currency: obj.currency
            }, 'ALL')

          if (this.autobetSettings.state) {
            this.autobetProcess(obj);
          }
        }
        if (obj.type == "betbutton") {
          this.state.gameBet = true;
        }
      }
    },
    claimCashback() {
      this.cashbackClaiming = true;
      this.cashbackHelper.claim();
    },
    calculateCashback(betAmount) {
      if (betAmount < 3000) {
        return (betAmount * 50) / 10000;
      } else if (betAmount < 5000) {
        return (betAmount * 60) / 10000;
      } else if (betAmount < 10000) {
        return (betAmount * 70) / 10000;
      } else {
        return (betAmount * 80) / 10000;
      }
    },
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
      this.wrongNetwork.state = false
    },
    getCashbackText() {
      return `<table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Bet amount</th>
                    <th scope="col">Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>= 10 000</td>
                    <td>0.8%</td>
                  </tr>
                  <tr>
                    <td>< 10 000</td>
                    <td>0.7%</td>
                  </tr>
                  <tr>
                    <td>< 5 000</td>
                    <td>0.6%</td>
                  </tr>
                  <tr>
                    <td>< 3 000</td>
                    <td>0.5%</td>
                  </tr>
                </tbody>
              <table>

              Example table:<br>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Bet amount</th>
                    <th scope="col">Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10 000</td>
                    <td>80</td>
                  </tr>
                  <tr>
                    <td>5 000</td>
                    <td>35</td>
                  </tr>
                  <tr>
                    <td>3 000</td>
                    <td>18</td>
                  </tr>
                  <tr>
                    <td>1 000</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>200</td>
                    <td>1</td>
                  </tr>
                </tbody>
              </table>

              * Cashback is rewarded only on lost bets<br>
              * Cashback is rewarded for any bet size<br>
              * Cashback has no wagering terms, can be claimed instantly<br>`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.itemColumn {
  display: inline-block;
}

.hittxt {
  color: #9e9e9e;
}

.clearleft {
  clear: left;
}

.betstabbottomline {
  height: 2px;
  background: #cfcfcf;
  width: 90%;
  margin: 0 auto;
}

.betstab {
  width: 30%;
  float: left;
  margin-left: 2px;
  color: var(--tabtxt-color);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(0deg, var(--tabgs-color) 0%, var(--tabge-color) 100%);
  transition: 0.3s;
}

.betstabactive {
  color: var(--tabtxtselected-color);
  background: linear-gradient(0deg, var(--tabys-color) 0%, var(--tabye-color) 100%);
  transition: 0.3s;
}

.betstabHolder {
  justify-content: center;
  align-items: center;
}

.buttons {
  margin: 60px 0px;
}

.kenoconnect,
.gameBet {
  max-width: 300px;
  margin: 0 auto;
}

.kenoconnect .betbutton {
  margin: 0 auto !important;
  float: none;
}

.kenomessage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #cbcbcb;
}

.kenocoeffitem {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-left: 1px solid #d4d4d4;
}

.kenocoeffline {
  height: 2px;
  background: #cfcfcf;
  width: 50%;
  margin: 0 auto;
}

.kenocoeffwin {
  color: #27ae60;
  transition: 0.3s;
  font-weight: bold;
}

.kenocoeffnormal {
  color: #474747;
  font-weight: bold;
  transition: 0.3s;
}

.kenomessage {
  z-index: 1;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.visible {
  opacity: 1;
  transition: 0.5s;
  pointer-events: auto;
}

.hidden {
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
}

.visiblefast {
  opacity: 1;
  pointer-events: auto;
}

.hiddenfast {
  opacity: 0;
  pointer-events: none;
}

.kenoitem {
  background: linear-gradient(0deg, #a8a8a8 0%, #b6b6b6 100%);
  border-radius: 100%;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  transition: 0.3s;
  border: 2px solid #a3a3a3;
  font-size: 1em;
}

.kenoitemselected {
  background: linear-gradient(0deg, var(--keno-selected-start) 0%, var(--keno-selected-end) 100%);
  border: 2px solid var(--keno-selected-border);
  color: var(--keno-selected-color);
  transition: 0.3s;
  font-size: 1em;
}

.kenoitemintersected {
  background: linear-gradient(0deg, var(--keno-intersected-start) 0%, var(--keno-intersected-end) 100%);
  border: 2px solid var(--keno-intersected-border);
  color: var(--keno-intersected-color);
  ;
  transition: 0.3s;
  font-size: 1em;
}

.kenoitemhit {
  background: linear-gradient(0deg, var(--keno-hit-start) 0%, var(--keno-hit-end) 100%);
  border: 2px solid var(--keno-hit-border);
  color: var(--keno-hit-color);
  ;
  transition: 0.3s;
  font-size: 1em;
}

.betinput {
  background: #d8d8d8;
  border-radius: 8px;
  font-size: 25px;
  border: none;
  text-align: center;
  padding: 5px;
  padding-top: 0px;
  max-width: 300px;
  width: 59%;
}

.betbutton {
  background: #262626;
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  padding: 5px 5px 5px 10px;
  margin-top: 10px;
  float: left;
}

.randombutton {
  background: #262626;
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  padding: 5px 10px;
  margin-top: 10px;
  margin-right: 10px;
  float: left;
}

.betModifiers {
  width: 18%;
  background: #262626;
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
  padding: 7px 0px;
  top: -2px;
  position: relative;
  margin-left: 5px;
}

.bet {
  width: 5em
}

.spinner-border {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: text-center;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  top: -3px;
  position: relative;
}

@media (max-width: 992px) {
  .buttons {
    margin: 0 auto;
    max-width: 90%;
    margin-top: 1.5em;
  }
}

@media (max-width: 401px) {
  .responsiveElipsis {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.claim {
  width: 100%;
  background: #fff;
  padding: 10px 12px;
}

.claim span {
  margin-top: 4px;
  display: inline-block;
}

.claim button {
  border: none;
  outline: none;
  padding: 5px 30px;
  border-radius: 5px;
  color: #333333;
}

.claim img {
  position: relative;
  top: -1px;
}
</style>