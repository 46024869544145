
import SoundManager from "@/utils/SoundManager";
import { store } from '@/store';
import Game from "../../services/game"
import Web3 from "web3";
import GameHelper from "../helpers/gameHelper.js";

export default class KenoGame extends Game {

  coeffsTable = [
    [],
    [0.7, 1.85],
    [.0, 2, 3.8],
    [.0, 1.1, 1.38, 26],
    [.0, .0, 2.20, 7.9, 90],
    [.0, .0, 1.5, 4.2, 13, 300],
    [.0, .0, 1.1, 2, 6.20, 100, 700],
    [.0, .0, 1.1, 1.6, 3.5, 15, 225, 700],
    [.0, .0, 1.1, 1.5, 2, 5.5, 39, 100, 800],
    [.0, .0, 1.1, 1.3, 1.7, 2.5, 7.5, 50, 250, 1000],
    [.0, .0, 1.1, 1.2, 1.3, 1.8, 3.5, 13, 50, 250, 1000]
  ]
  coefs = []
  board = []

  win = 0
  result = []
  intersected = -1
  amount = 0.001
  tx_id = ''
  gameHelper = null

  constructor(_messageHandler, _Contract, _game) {
    super(_messageHandler, _Contract, _game)
    this.gameHelper = new GameHelper(_game)
    for (let index = 0; index < 40; index++) {
      this.board.push({ index: index + 1, state: 0, cssclass: '' });
    }
    this.coefs = []
    for (let index = 0; index < 11; index++) {
      this.coefs.push(
        {
          active: false,
          coeff: 0
        })
    }
  }

  betHandler(amount) {
    this.result = []
    this.intersected = -1;
    for (let index = 0; index < this.board.length; index++) {
      const element = this.board[index];
      element.cssclass = ''
      if (element.state == 1) {
        element.cssclass = 'kenoitemselected'
      }
    }


    if (!this.connected) {
      this.messageHandler({ type: 'error', msg: 'Not connected' })
      return
    }

    var selected = this.getSelectedItems()
    if (selected.length > 10) {
      this.messageHandler({ type: 'error', msg: 'Maximum 10 balls' })
      return;
    }
    if (selected.length == 0) {
      this.messageHandler({ type: 'error', msg: 'You must select at least one ball' })
      return;
    }
    this.amount = amount;
    this.messageHandler({ type: 'betloader' })
    if (localStorage) {
      localStorage.setItem('betAmount', this.amount);
    }
    if (this.tokenObj.address == '0x0000000000000000000000000000000000000000') {
      this.betMainHandler(amount, selected)
    } else {
      this.betTokenHandler(amount, selected)
    }
  }

  async betTokenHandler(amount, selected) {
    var betAmt = Web3.utils.toWei("" + amount, this.tokenObj.decimals)

    var sendData = {
      from: this.account
    }


    if (this.tokenObj.extraFeeGasLimit > 0) {
      var gasLimit = await this.contract.methods.bet(selected, this.tokenObj.address, betAmt).estimateGas(
        { from: this.account }
      )
      gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)

      var gasPrice = await window.web3.eth.getGasPrice()
      gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)


      sendData.gasLimit = gasLimit
      sendData.gasPrice = gasPrice
    }




    console.log(sendData)



    this.contract.methods
      .bet(selected, this.tokenObj.address, betAmt)
      .send(sendData)
      .then(function (result) {
        this.win = Web3.utils.fromWei(result.events.Bet.returnValues.win, this.tokenObj.decimals);
        this.intersected = 0;
        this.result = []
        this.tx_id = result.transactionHash
        for (let index = 0; index < result.events.Bet.returnValues.output.length; index++) {
          const element = result.events.Bet.returnValues.output[index];
          this.result.push(element)
        }
        this.nextBall();

      }.bind(this), function (params) {
        window.Logger.log(params)
        this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
        this.messageHandler({ type: 'betbutton' })
      }.bind(this))

  }

  async betMainHandler(amount, selected) {

    var sendData = {
      from: this.account,
      value: Web3.utils.toWei("" + amount, 'ether'),
    }

    if (this.tokenObj.extraFeeGasLimit > 0) {
      var gasLimit = await this.contract.methods.bet(selected).estimateGas(
        {
          from: this.account,
          value: Web3.utils.toWei("" + amount, 'ether')
        }
      )
      gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)

      var gasPrice = await window.web3.eth.getGasPrice()
      gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)


      sendData.gasLimit = gasLimit
      sendData.gasPrice = gasPrice
    }





    console.log(sendData)


    this.contract.methods
      .bet(selected)
      .send(sendData)
      .then(function (result) {
        this.win = Web3.utils.fromWei(result.events.Bet.returnValues.win, 'ether');
        this.intersected = 0;
        this.result = []
        this.tx_id = result.transactionHash
        for (let index = 0; index < result.events.Bet.returnValues.output.length; index++) {
          const element = result.events.Bet.returnValues.output[index];
          this.result.push(element)
        }
        this.nextBall();

      }.bind(this), function (params) {
        this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
        this.messageHandler({ type: 'betbutton' })
      }.bind(this))

  }

  nextBall() {
    if (this.result.length == 0) {

      if (this.erc20Game) {
        this.erc20Game.balanceOf()
      } else {
        this.fetchAccount();
      }


      this.messageHandler({
        type: 'betfinished',
        bet: this.amount,
        win: this.win,
        tx_id: this.tx_id,
        address: this.playeraddress,
        currency: this.tokenObj.name
      })
      return;
    }
    var element = this.result.pop();
    var boardElement = this.board[element - 1];
    if (boardElement.state == 0) {
      boardElement.cssclass = 'kenoitemhit';
    } else if (boardElement.state == 1) {
      boardElement.cssclass = 'kenoitemintersected';
      this.intersected++;
    }
    new SoundManager().play({ name: 'bubble' })
    setTimeout(function () {
      this.nextBall()
    }.bind(this), store.getters.getFastOn ? 50 : 150);

  }

  clickHandler(id) {
    var selected = this.getSelectedItems()
    if (this.board[id - 1].state == 0) {
      if (selected.length >= 10) {
        this.messageHandler({ type: 'error', msg: 'Maximum 10 balls' })
        return;
      }
    }

    for (let index = 0; index < this.board.length; index++) {
      const element = this.board[index];
      element.cssclass = ''
      if (element.state == 1) {
        element.cssclass = 'kenoitemselected'
      }
    }


    this.board[id - 1].state = 1 - this.board[id - 1].state
    if (this.board[id - 1].state == 1) {
      this.board[id - 1].cssclass = 'kenoitemselected';
    } else {
      this.board[id - 1].cssclass = '';
    }

    //kenoitemselected kenoitemintersected kenoitemhit 

    selected = this.getSelectedItems()
    //this.coefs = this.coeffsTable[selected.length].slice()
    this.coefs = []
    var coefssTmp = this.coeffsTable[selected.length].slice();
    for (let index = 0; index < 11; index++) {
      if (index < coefssTmp.length) {
        this.coefs.push(
          {
            active: true,
            coeff: coefssTmp[index]
          })
      } else {
        this.coefs.push(
          {
            active: false,
            coeff: 0
          })
      }
    }

    this.coefs.reverse()

  }

  random10(randomLength = 10) {
    for (let index = 0; index < this.board.length; index++) {
      const element = this.board[index];
      element.cssclass = ''
      element.state = 0
    }

    var tmpArr = Array.from(Array(40).keys());
    this.shuffleArray(tmpArr)
    for (let index = 0; index < randomLength; index++) {
      const element = this.board[tmpArr[index]];
      element.cssclass = 'kenoitemselected'
      element.state = 1
    }


    var selected = this.getSelectedItems()
    //this.coefs = this.coeffsTable[selected.length].slice()
    this.coefs = []
    var coefssTmp = this.coeffsTable[selected.length].slice();
    for (let index = 0; index < 11; index++) {
      if (index < coefssTmp.length) {
        this.coefs.push(
          {
            active: true,
            coeff: coefssTmp[index]
          })
      } else {
        this.coefs.push(
          {
            active: false,
            coeff: 0
          })
      }
    }

    this.coefs.reverse()
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }

  getSelectedItems() {
    var selected = []
    for (let index = 0; index < this.board.length; index++) {
      const element = this.board[index];
      if (element.state == 1) {
        selected.push(element.index)
      }
    }
    return selected
  }

}

