<template>
  <div style="display: block; float: right">
    <button
      class="betModifiers autobet"
      data-bs-toggle="modal"
      data-bs-target="#autoBet"
    >
      <i class="tio-settings"></i> Auto
    </button>
    <div
      class="modal fade"
      id="autoBet"
      tabindex="-1"
      aria-labelledby="autoBetModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="autoBetModalLabel">Autobet Settings</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row pa-0 ma-0">
              <div class="col-sm-12">
                <div class="settings">
                  <label>On Win</label>
                  <div class="inputs">
                    <span
                      class="borders switch"
                      :class="!autobet.onWinState ? 'enabled' : ''"
                      @click="
                        autobet.onWin = 0;
                        autobet.onWinState = false;
                      "
                      >Reset</span
                    >
                    <span
                      class="borders switch"
                      :class="autobet.onWinState ? 'enabled' : ''"
                      @click="autobet.onWinState = true"
                      >Increase by</span
                    >
                    <input
                      class="borders input"
                      type="number"
                      min="1"
                      step="1"
                      v-model="autobet.onWin"
                    /><span class="inputAddon">%</span>
                  </div>
                  <div v-if="game.game == 'keno'" class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      model="autobet.randomNumbers.onWin"
                      @change="setWinRandom"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      New random numbers
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="settings mt-3">
                  <label>On Loss</label>
                  <div class="inputs">
                    <span
                      class="borders switch"
                      :class="!autobet.onLossState ? 'enabled' : ''"
                      @click="
                        autobet.onLoss = 0;
                        autobet.onLossState = false;
                      "
                      >Reset</span
                    >
                    <span
                      class="borders switch"
                      :class="autobet.onLossState ? 'enabled' : ''"
                      @click="autobet.onLossState = true"
                      >Increase by</span
                    >
                    <input
                      class="borders input"
                      type="number"
                      min="1"
                      step="1"
                      v-model="autobet.onLoss"
                    /><span class="inputAddon">%</span>
                  </div>
                  <div v-if="game.game == 'keno'" class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      model="autobet.randomNumbers.onLoss"
                      @change="setLossRandom"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      New random numbers
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 mt-3">
                <div class="settings">
                  <label>Stop on Profit</label>
                  <div class="inputs">
                    <input
                      class="borders input"
                      type="number"
                      min="1"
                      step="1"
                      v-model="autobet.onProfitWin"
                    /><span class="inputAddon">{{ $store.getters.getCurrency }}</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 mt-3">
                <div class="settings">
                  <label>Stop on Loss</label>
                  <div class="inputs">
                    <input
                      class="borders input"
                      type="number"
                      min="1"
                      step="1"
                      v-model="autobet.onProfitLoss"
                    /><span class="inputAddon">{{ $store.getters.getCurrency }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="!autobet.state"
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="test"
              @click="autoBetStart"
              :disabled="
                autobet.onWin <= 0 &&
                autobet.onLoss <= 0 &&
                autobet.onProfit <= 0 &&
                autobet.onProfitLoss <= 0
              "
            >
              Start Autobet
            </button>
            <button
              v-if="autobet.state"
              type="button"
              class="btn btn-primary"
              @click="autoBetStop"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Stop Autobet
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      autobet: {
        state: this.autobetState,
        onWin: 0,
        onLoss: 0,
        onProfitWin: 0,
        onProfitLoss: 0,
        profit: 0,
        bet: {
          initial: 0,
          current: 0,
        },
        randomNumbers: {
          onWin: false,
          onLoss: false,
          make: false,
        },
        onWinState: false,
        onLossState: false,
      },
    };
  },
  methods: {
    autoBetStart() {
      this.autobet.state = true;
      this.$emit("autobet", this.autobet);
    },
    autoBetStop() {
      this.autobet.state = false;
    },
    setLossRandom(v) {
      this.autobet.randomNumbers.onLoss = v.path[0].checked;
    },
    setWinRandom(v) {
      this.autobet.randomNumbers.onWin = v.path[0].checked;
    },
  },
  watch: {
    "autobet.state": function (v) {
      if (!v) this.autobet.profit = 0;
    },
    "autobet.onWin": function (v) {
      if (v < 0) {
        this.autobet.onWinState = false;
        return;
      }
      this.autobet.onWinState = true;
    },
    "autobet.onLoss": function (v) {
      if (v < 0) {
        this.autobet.onLossState = false;
        return;
      }
      this.autobet.onLossState = true;
    },
  },
  props: ["game", "autobetState"],
};
</script>
<style scoped>
.input {
  padding: 10px 20px;
  padding-right: 30px;
  outline: none;
  width: 180px;
}
.inputAddon {
  position: relative;
  left: -25px;
  color: rgb(147, 153, 168);
}
.borders,
.input:focus-visible {
  border-radius: 5px;
  border: 1px solid rgba(222, 223, 228, 0.5);
}
.switch {
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 10px;
  display: inline-block;
}
.switch.enabled {
  background-color: #c0c0c0;
}
.inputs {
  padding: 10px 10px 10px 0px;
  margin-top: -5px;
}
div {
  font-weight: 400 !important;
  font-family: "Open Sans", sans-serif;
}
label {
  display: block;
  color: rgb(147, 153, 168);
}
.settings {
  text-align: left;
}
.btn-primary {
  background: #262626;
  color: #ffffff;
}
.betModifiers {
  background: #262626;
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
  padding: 5px 12px;
  position: relative;
  top: 10px;
  font-size: 18px;
  font-weight: 700;
}
</style>
